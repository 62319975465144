export const bundleID = 'spring.gxl.fish'
export const bundleID4X = 'xgc.gxl.fish'
export const bundleID4SD = 'sd.qincilongzang.net'

export const androidDownloadUrl = `https://a.app.qq.com/o/simple.jsp?pkgname=${bundleID}`
export const iOSDownloadUrl = 'https://apps.apple.com/cn/app/id1594612679'

export const androidDownloadUrl4SD =
  'https://lostradio-hangzhou.oss-cn-hangzhou.aliyuncs.com/apk/sd.apk'
export const iOSDownloadUrl4SD = 'https://apps.apple.com/cn/app/id1626786039'

export const androidDownloadUrl4X = `https://a.app.qq.com/o/simple.jsp?pkgname=${bundleID4X}`
export const iOSDownloadUrl4X = 'https://apps.apple.com/cn/app/id6444424810'
export const directDownloadUrl4X = `https://cdn1.iniucha.com/乡广场.apk`

export const ONLINESTATUS = {
  1: '在线',
  2: '离线',
  3: '忙碌',
}
